import React from 'react';
import { useApi } from '../../../hooks';
import _ from '../../../utils';

const Fade = require('react-reveal/Fade');

export default function SpotifyEmbed() {
  const { isLoading, hasErrors, data } = useApi('podcasts');

  if (isLoading || hasErrors) {
    return null;
  }

  const currentPodcastMeta = _.randomize(data.podcasts);

  return (
    <>
      <Fade ssrFadeout bottom distance="30px">
        <iframe
          title={currentPodcastMeta?.name}
          src={currentPodcastMeta?.embed_url}
          width="100%"
          height="232"
          frameBorder="0"
          allow="encrypted-media"
          loading="lazy"
          style={{ marginBottom: '0' }}
        ></iframe>
      </Fade>
    </>
  );
}
