import React from 'react';
import Layout from '../components/layout';
import FourOFour from '../components/404/';
import page from '../HOCs/page';

const NotFound = () => {
  return (
    <Layout>
      <FourOFour />
    </Layout>
  );
};

export default page(NotFound, '404');
