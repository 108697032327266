const _ = {
  lowercase: (string: string) => string.toLowerCase(),
  includes: (source: string, target: string) => source.includes(target),
  /**
   * @see {@link https://github.com/kyleshevlin/blog/blob/7b9028f52f00ebd180a8cd798c965d602db689df/src/utils/index.js#L10 Github}
   */
  inflect: (singular: string, plural: string, number: number) =>
    number === 1 ? singular : plural,
  randomize: (array: any[]) => array[Math.floor(Math.random() * array.length)],
};

export default _;
