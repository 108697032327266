import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SpotifyPodcastsEmbed from '../Embeds/Spotify';
import Fade from 'react-reveal/Fade';
import styles from './404.module.scss';

const {
  container,
  title,
  content,
  backHomeLink,
  illustrationContainer,
  floating,
} = styles;

const FourOFour = () => {
  let {
    file: {
      childImageSharp: { gatsbyImageData },
    },
  } = useStaticQuery(graphql`
    {
      file(
        name: { eq: "404" }
        sourceInstanceName: { eq: "static" }
        extension: { eq: "png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
            quality: 80
          )
        }
      }
    }
  `);

  return (
    <Fade ssrReveal cascade top distance="20px">
      <div className={container}>
        <Illustration src={gatsbyImageData} />
        <h1 className={title}>This Page is Lost in Space!</h1>
        <div className={content}>
          <p className={`404-message ${backHomeLink}`}>
            You can go back to <Link to="/">where this all began</Link> or maybe{' '}
            <Link to="/blog/">read few articles</Link>.
          </p>
          <SpotifyPodcastsEmbed />
        </div>
      </div>
    </Fade>
  );
};

const Illustration = ({ src, ...props }) => (
  <div className={illustrationContainer} {...props}>
    <GatsbyImage image={src} alt="404 Illustration" className={floating} />
  </div>
);

/* const Message = ({ ...props }) => (
    <div className={messageWrapper} {...props}>
        <p className={message}>
            You thought this mission to the moon would be a quick six month thing. Your neighbor
            offered to look after your dog. Your high school math teacher was impressed. He once
            said you wouldn’t amount to anything. You sure showed him. But now here you are, fifty
            feet from your spaceship with no way to get back. Your dog will be so sad. Your math
            teacher will be so smug. Pretty devastating.
        </p>
    </div>
) */

export default FourOFour;
